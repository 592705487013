import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadCollection, setItem, setLayout } from '../../lib/actions'
import { Helmet } from 'react-helmet-async'

import ListItem from '../../components/Organisms/ListItem/ListItem'
import Hero from '../../components/Organisms/Hero/Hero'

import './Projects.scss'

class Projects extends Component {

    componentDidMount() {
        this.props.loadCollection("projects", { _sort: "order:ASC" }).then(() => {
            this.props.setLayout({ header: this.props.collection[0]?.overlay_color, footer: "black", className: "" })
        })
    }

    render() {
        return (
            <div className="projects">
                {this.props.collection.map((item, index) =>
                    index === 0
                        ?
                        <Hero
                            item={item}
                            claim={this.props.globals.claim}
                            onClick={() => this.props.setItem(item, "project")}
                            key={item.slug}
                        />

                        :
                        <ListItem
                            item={item}
                            key={item.slug}
                            onClick={() => this.props.setItem(item, "project")}
                        />
                )}
                <Helmet>
                    <title>{this.props.globals.title + " - " + this.props.globals.claim}</title>
                </Helmet>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        collection: state.collection,
        globals: state.globals
    }
}

export default connect(
    mapStateToProps,
    { loadCollection, setItem, setLayout }
)(Projects);