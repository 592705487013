import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadSingle, setLayout } from '../../lib/actions'
import ReactMarkdown from 'react-markdown'

import './TextPage.scss'
import { Helmet } from 'react-helmet-async'

class TextPage extends Component {

    componentDidMount() {
        this.props.loadSingle(this.props.match.params.slug)
        this.props.setLayout({ header: "black", footer: "black", className: "" })
    }

    componentWillReceiveProps(nextProps) {
        this.props.loadSingle(nextProps.match.params.slug)
        this.props.setLayout({ header: "black", footer: "black", className: "" })
    }

    
    render() {
        // console.log(this.props.match.params.slug)
        return (
            <div className="text-page container">
                <h1>
                    {this.props.item.title}
                </h1>
                <ReactMarkdown source={this.props.item.text} className="contents" />
                <Helmet>
                    <title>{"Art Visit Studios - " + this.props.item.title}</title>
                    <meta property="og:title" content={"Art Visit Studios - " + this.props.item.title} />
                    <meta property="og:description" content={this.props.item.text} />
                    <meta name="description" content={this.props.item.text} />
                </Helmet>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        item: state.item,
        user: state.user,
        userState: state.userState
    }
}

export default connect(
    mapStateToProps,
    { loadSingle, setLayout }
)(TextPage);