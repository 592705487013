import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { loginUser, play } from '../../lib/actions'

import { Button } from '../../components/Atoms/Button'
import Checkbox from '../../components/Atoms/Checkbox'
import FormLink from '../../components/Atoms/FormLink'
import { withRouter } from 'react-router-dom'

class LoginForm extends Component {

    state = {
        email: "",
        password: "",
        remember: "",
    }

    handleSubmit = event => {
        event.preventDefault()
        this.props.loginUser(this.state)
            .then(() => {
                this.props.userState === "loggedIn" && this.props.history.push(this.props.location.state.origin)
                this.props.item.type === "project" && this.props.play(true)
            })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => { })
    }

    render() {
        if (this.props.userState === "loggedIn") {
            return (
                <Fragment>
                    <div className="logo black">
                        <h1>
                            Art Visit Studios
                        </h1>
                    </div>
                    <h2>You're already logged in.</h2>
                </Fragment>
            )
        } else {
            return (
                <form>
                    <div className="logo black">
                        <h1>
                            Art Visit Studios
                        </h1>
                    </div>
                    {
                        this.props.userState !== ""
                            ?
                            <h4 className="error">Incorrect password or EMail. Please try again!</h4>
                            :
                            <Fragment>
                                <h3 className="half">
                                    Log in
                                </h3>
                                <FormLink to={{ pathname: '/ticket/register', state: { modal: true, origin: this.props.location.state.origin } }} >
                                    Register
                                </FormLink>
                            </Fragment>
                    }

                    <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="E-Mail"
                        value={this.state.email}
                        onChange={this.handleChange}
                    />
                    <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                    />
                    <Checkbox
                        id={"remember"}
                        value={this.state.remember}
                        onChange={this.handleChange.bind(this)}
                    >
                        Remember me
                    </Checkbox>
                    <FormLink to={{ pathname: '/ticket/forgot', state: { modal: true, origin: this.props.location.state.origin } }} >
                        Forgot Password?
                    </FormLink>
                    <Button black type="submit" onClick={this.handleSubmit}>
                        Login
                    </Button>
                </form>
            )
        }

    }
}

const mapStateToProps = (state) => {
    return {
        globals: state.globals,
        userState: state.userState,
        user: state.user,
        item: state.item
    }
}


export default withRouter(connect(
    mapStateToProps,
    { loginUser, play }
)(LoginForm))