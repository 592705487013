import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { loadCollectionItem, setLayout, play } from '../../lib/actions'
import { Helmet } from 'react-helmet-async'

import VrPlayer from '../../components/Molecules/VrPlayer/VrPlayer'

import './Items.scss'
import ReactMarkdown from 'react-markdown'

class ProjectItem extends Component {

    componentDidMount() {
        // console.log('item mount')
        if (this.props.item.type !== "project") this.props.loadCollectionItem("projects", this.props.match.params)
        this.props.setLayout({ header: "black", footer: "black", className: "" })
        this.props.play(false)
    }

    componentWillUnmount() {
        // console.log('item unmount')
        this.props.play(false)
    }

    render() {
        return (
            <Fragment>
                <VrPlayer
                    item={this.props.item}
                    match={this.props.match}
                    // always pretend we're loggedIn
                    loggedIn={true}
                    // loggedIn={this.props.userState === "loggedIn"}
                    playing={this.props.playing}
                    play={this.props.play}
                />
                <div className="container item">
                    <div className="headings">
                        <h1>
                            {this.props.item.title}
                        </h1>
                        <h2>
                            {this.props.item.location}
                        </h2>
                        <h3>
                            {this.props.item.time}
                        </h3>
                    </div>
                    <div className="row">
                        <ReactMarkdown source={this.props.item.text} className="text half" />
                    </div>
                </div>
                <Helmet>
                    <title>{this.props.globals.title + " - " + this.props.item.title}</title>
                    <meta property="og:title" content={this.props.globals.title + " - " + this.props.item.title} />
                    <meta property="og:description" content={this.props.item.text} />
                    <meta name="description" content={this.props.item.text} />
                </Helmet>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        item: state.item,
        user: state.user,
        userState: state.userState,
        playing: state.playing,
        globals: state.globals,
    }
}

export default connect(
    mapStateToProps,
    { loadCollectionItem, setLayout, play }
)(ProjectItem);