import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CardText from '../../Molecules/CardText/CardText'
import ReactPlayer from 'react-player'

import './ListItem.scss'
import ResponsiveImage from '../../Atoms/ResponsiveImage'

class ListItem extends Component {

    state = {
        playing: false
    }

    play = () => {
        this.setState({ playing: true })
    }

    pause = () => {
        this.setState({ playing: false })
    }


    width = window.innerWidth
    height = this.width * 9 / 16

    render() {
        return (
            this.props.item
                ?
                <div
                    className="list-item"
                    key={this.props.item.slug}
                    onMouseEnter={this.play}
                    onMouseLeave={this.pause}
                >
                    <ResponsiveImage
                        className={this.state.playing ? "poster" : "poster active"}
                        image={this.props.item.poster}
                        alt={this.props.item.location}
                    />
                    <ReactPlayer
                        className="player"
                        playing={this.state.playing}
                        url={this.props.item.teaser}
                        config={{
                            vimeo: {
                                background: true,
                            }
                        }}
                        fileConfig={{ attributes: { poster: this.props.item.poster.url } }}
                        loop={true}
                        muted={true}
                        width={"100%"}
                        height={"100%"}
                        playsinline={true}
                    />
                    <Link
                        to={"/projects/" + this.props.item.slug}
                        className="text"
                        onClick={this.props.onClick}
                    >
                        <CardText
                            title={this.props.item.title}
                            lines={[this.props.item.location, this.props.item.time]}
                            style={this.props.item.overlay_color}
                        />
                    </Link>
                </div>

                :
                <div className="list-item"></div>
        )
    }

}

export default ListItem