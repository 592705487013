import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteUser } from '../../lib/actions'
import { withRouter } from 'react-router-dom'
import { Button } from '../../components/Atoms/Button'

class Delete extends Component {

    handleSubmit = () => {
        this.props.deleteUser(this.props.user.userdata)
              .then(() => {this.props.history.push("/")})
    }

    render() {
            return (
                <form>
                    <div className="logo black">
                        <h1>
                            Art Visit Studios
                        </h1>
                    </div>
                    <h3>Are you sure you want to delete your account?</h3>
                    <Button black type="submit" onClick={this.handleSubmit}>
                        Confirm
                    </Button>
                </form>
            )

    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default withRouter(connect(
    mapStateToProps,
    { deleteUser }
)(Delete))