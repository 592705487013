import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import { registerUser } from '../../lib/actions'

import Select from 'react-select'
import countryList from 'react-select-country-list'
import ReactMarkdown from 'react-markdown'

import { Button } from '../../components/Atoms/Button'

class RegisterForm extends Component {

    constructor(props) {
        super(props)

        this.countries = countryList().getData()

        this.state = {
            countries: this.countries,
            selectedCountry: "",
            user: {
                email: "",
                password: "",
                confirm: "",
                name: "",
                lastname: "",
                country: "",
            },
        }
    }

    handleSubmit = event => {
        event.preventDefault()
        this.props.registerUser(this.state.user)
            .then(() => {

                this.props.userState === "loggedIn" && window.setTimeout(() => {
                    this.props.history.push(this.props.location.state.origin)
                }, 3000)
            })
    }

    handleChange = event => {
        const name = event.target.name
        const value = event.target.value
        this.setState(state => ({
            user: {
                ...state.user,
                [name]: value
            }
        }))
    }

    changeCountry = event => {
        this.setState({ selectedCountry: event })
        this.setState(state => ({
            user: {
                ...state.user,
                country: event.value
            }
        }))
    }


    render() {
        if (this.props.registrationState === "OK") {
                return (
                    <Fragment>
                        <div className="logo black">
                            <h1>
                                Art Visit Studios
                            </h1>
                        </div>
                       {this.props.globals.registration && <ReactMarkdown source={this.props.globals.registration.succeeded} className="success" />}
                    </Fragment>
                )
        } else {
            return (
                <form className="register">
                    <div className="logo black">
                        <h1>
                            Art Visit Studios
                        </h1>
                    </div>
                    
                    {
                        this.props.registrationState
                        ? <h2 className="error">{this.props.registrationState}</h2>
                        : <h3 className="register">Register</h3>
                    }

                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        className="half"
                        value={this.state.user.name}
                        onChange={this.handleChange}
                    />
                    <input
                        type="text"
                        id="lastname"
                        name="lastname"
                        placeholder="Lastname"
                        className="half"
                        value={this.state.user.lastname}
                        onChange={this.handleChange}
                    />
                    <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="E-Mail"
                        value={this.state.user.email}
                        onChange={this.handleChange}
                    />
                    <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        value={this.state.user.password}
                        onChange={this.handleChange}
                    />
                    <input
                        type="password"
                        id="confirm"
                        name="confirm"
                        placeholder="Confirm Password"
                        value={this.confirm}
                        onChange={this.handleChange}
                    />
                    <Select
                        options={this.state.countries}
                        value={this.state.selectedCountry}
                        onChange={this.changeCountry}
                        style={{ width: "100%" }}
                        styles={{
                            container: base => ({
                                ...base,
                                width: "100%",
                                height: "36px",
                                fontSize: "12px",
                                textTransform: "uppercase",
                                margin: "10px 0",
                                zIndex: 5,
                            }),
                            control: base => ({
                                ...base,
                                border: "solid 1px black",
                                height: "36px",
                            }),
                            input: base => ({ ...base, paddingLeft: "10px" }),
                            singleValue: base => ({ ...base, paddingLeft: "10px" }),
                            placeholder: base => ({ ...base, paddingLeft: "10px" }),
                        }}
                        placeholder={"Country"}
                        menuColor='black'
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightgrey',
                                primary50: 'grey',
                                primary75: 'darkgrey',
                                primary: 'black',
                            },
                        })}
                    />
                    {this.props.globals.registration && <ReactMarkdown source={this.props.globals.registration.disclaimer} className="disclaimer" />}
                    <Button black onClick={this.handleSubmit}>
                        Register
                    </Button>
                </form>
            )
        }


    }
}

const mapStateToProps = (state) => {
    return {
        registrationState: state.registrationState,
        globals: state.globals,
        userState: state.userState
    }
}

export default withRouter(connect(
    mapStateToProps,
    { registerUser }
)(RegisterForm))