import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const API_URL = process.env.REACT_APP_API_URL

const ResponsiveImage = ({ image, alt, className, maxWidth }) => {

    if (image) {
        let srcset = ""
        if (image.formats && image.formats !== null && image.formats !== undefined) {
            Object.entries(image.formats).forEach((item) => {
                srcset += API_URL + item[1].url + " " + item[1].width + "w, "
            })
        }

        return (
            <div className={className}>
                <LazyLoadImage
                    alt={alt}
                    threshold={250}
                    src={API_URL + image.url}
                    sizes={`(max-width: ${window.innerWidth}px)`}
                    srcSet={srcset}
                    width={"100%"}
                    height={"auto"}
                    visibleByDefault={true}
                />
                <span>{image.caption}</span>
            </div>
        )
    } else {
        return (<div></div>)
    }
};

export default ResponsiveImage;